body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Light.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Regular.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Medium.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Medium Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A SemiBold.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Bold.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularA";
  src: local("Euclid Circular A Bold Italic.ttf"), url("/src/assets/fonts/euclid-a/Euclid Circular A Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Light.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Light.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Regular.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Medium Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Medium Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B SemiBold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B SemiBold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: local("Euclid Circular B Bold Italic.ttf"), url("/src/assets/fonts/euclid-b/Euclid Circular B Bold Italic.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}



@font-face {
  font-family: "Aeonik";
  src: local("AeonikLight.otf"), url("/src/assets/fonts/aeonik/AeonikLightItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: local("AeonikLight.otf"), url("/src/assets/fonts/aeonik/AeonikLight.otf") format("opentype");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: "Aeonik";
  src: local("AeonikRegular.otf"), url("/src/assets/fonts/aeonik/AeonikRegular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: local("AeonikRegularItalic.otf"), url("/src/assets/fonts/aeonik/AeonikRegularItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: local("AeonikBold.otf"), url("/src/assets/fonts/aeonik/AeonikBold.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: local("AeonikBoldItalic.otf"), url("/src/assets/fonts/aeonik/AeonikBoldItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "GoogleSans";
  src: local("Google Sans.ttf"), url("/src/assets/fonts/google-sans/GoogleSans-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
